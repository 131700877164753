import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get, child } from 'firebase/database';
import ReactMarkdown from 'react-markdown';
import '../styles/story.scss';

function Story({ id }) {
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState(''); // For storing fetched Markdown content

  // Fetch the article metadata from Firebase
  const fetchArticle = async () => {
    try {
      const dbRef = ref(getDatabase());
      const snapshot = await get(child(dbRef, `articles/${id}`));
      if (snapshot.exists()) {
        const data = snapshot.val();
        setArticle({ id, ...data });

        // Fetch the Markdown content from the Gist URL
        if (data.content) {
          await fetchGistContent(data.content);
        }
      } else {
        console.error("No data available");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch Markdown content from a Gist URL
  const fetchGistContent = async (url) => {
    try {
      const response = await fetch(url);
      if (response.ok) {
        const markdown = await response.text();
        setContent(markdown); // Set the Markdown content
      } else {
        console.error(`Failed to fetch Gist content: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error fetching Gist content:", error);
    }
  };

  // Fetch article when component mounts or ID changes
  useEffect(() => {
    if (id) {
      fetchArticle();
    }
  }, [id]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!article) {
    return <p>Article not found.</p>;
  }

  return (
    <div className="story-container">
      {article.image && (
        <div className="image-container">
            <div className="image-wrapper">
                <img src={article.image} alt={article.title} />
            </div>
        </div>
      )}
      <h2>{article.title}</h2>
      {/* Render the Markdown content fetched from the Gist */}
      <div className="markdown-content">
        <ReactMarkdown>{content}</ReactMarkdown>
      </div>
    </div>
  );
}

export default Story;
