import React, { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import { Link } from 'react-router-dom';
import '../styles/human.scss';

const Human = () => {
  const [init, setInit] = useState(false);
  const [showPatchNotes, setShowPatchNotes] = useState(false);
  const [patchNotes, setPatchNotes] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Initialize Particles
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });

    // Fetch Patch Notes from Gist
    fetch(
      "https://gist.githubusercontent.com/LoriV2/bd3047649334b8be5aaf4481637dc9b6/raw/21020b74828e80c6bf0cd0ea8dc4a0ed5740e226/humanchangelog.txt"
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch patch notes.");
        }
        return response.text();
      })
      .then((text) => {
        const parsedNotes = parsePatchNotes(text);
        setPatchNotes(parsedNotes);
      })
      .catch((err) => setError(err.message));
  }, []);

  // Toggle Patch Notes visibility
  const togglePatchNotes = () => {
    setShowPatchNotes(!showPatchNotes);
  };

  // Parse patch notes from the raw text file
  const parsePatchNotes = (text) => {
    return text
      .split(/\n\n/) // Oddziel bloki tekstu
      .map((block) => {
        const lines = block.split("\n");
        const header = lines.shift(); // Pierwsza linia to nagłówek
        const match = header.match(/(v[0-9.]+)\s-\s(.+)\s(\d{2}\.\d{2}\.\d{4})/);
  
        if (!match) {
          console.error("Invalid header format:", header);
          return null; // Ignoruj niepoprawne wpisy
        }
  
        const [version, title, date] = match; // Rozbij header na części
        const description = lines.join(" ").trim(); // Pozostałe linie to opis
  
        return { version, title, date, description };
      })
      .filter((note) => note !== null); // Usuń niepoprawne wpisy
  };
  

  if (!init) return null;

  return (
    <div className="human-page">
      <Particles
        id="tsparticles"
        options={{
          background: { color: { value: "#232E21" } },
          fullScreen: { enable: true, zIndex: -1 },
          particles: {
            color: { value: ["#F3EFE0", "#bbaaf3", "#008000"] },
            move: {
              enable: true,
              direction: "none",
              speed: 1,
              outModes: { default: "out" },
            },
            number: { value: 100, density: { enable: true, width: 1920, height: 1080 } },
            size: { value: { min: 1, max: 4 }, random: true },
            opacity: { value: { min: 0.1, max: 0.7 }, random: true },
            shape: { type: ["circle", "star"] },
            links: { enable: true, distance: 220, color: "#8d1a98", opacity: 0.5, width: 1 },
            wobble: { enable: true, distance: 20, speed: 15 },
          },
          responsive: [
            { maxWidth: 768, options: { particles: { number: { value: 100 }, links: { distance: 80 } } } },
          ],
        }}
      />
      <div className="human-page__content">
        <h1>Human: Discord Bot</h1>
        <p>Embark on a journey of capitalism and discovery with advanced economics and the rich lore of Astelta.</p>
        <div className="human-page__discord-bot">
          <h2>About Human Bot</h2>
          <p>
            Human is a feature-rich companion offering an advanced economic system and an immersive journey through
            the lore and history of Astelta. Manage resources, build empires, and uncover the secrets of this
            fantasy world!
          </p>
          <a
            href="https://discord.com/oauth2/authorize?client_id=1038919221440163941"
            target="_blank"
            className="human-page__discord-bot-link"
            rel="noopener noreferrer"
          >
            Invite Human
          </a>
        </div>
      </div>

      <div className="human-page__patch-notes">
        <button className="human-page__patch-notes-toggle" onClick={togglePatchNotes}>
          📝
        </button>
        <div className={`human-page__patch-notes-content ${showPatchNotes ? 'visible' : ''}`}>
          <h3>Patch Notes</h3>
          {error ? (
            <p>Error loading patch notes: {error}</p>
          ) : (
            <>
              {patchNotes.length > 0 && (
                <>
                  <div className="latest-patch">
                    <h4>{patchNotes[0].version}</h4>
                    <p>{patchNotes[0].description}</p>
                    <small>{patchNotes[0].date}</small>
                  </div>
                  <ul>
                    {patchNotes.slice(1, 5).map((note, index) => (
                      <li key={index}>
                        <p className="dates">{note.version}</p>
                      </li>
                    ))}
                  </ul>
                  {patchNotes.length > 5 && (
                    <Link to="https://gist.githubusercontent.com/LoriV2/bd3047649334b8be5aaf4481637dc9b6/raw/21020b74828e80c6bf0cd0ea8dc4a0ed5740e226/humanchangelog.txt"><button>Read More</button></Link>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Human;
