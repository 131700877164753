export const metadata = {
    home: {
      title: "Home",
      description: "Our homepage come and relax as you delve in Astelta's story",
      imageUrl: "https://yoursite.com/welcome-image.jpg",
    },
    about: {
      title: "About",
      description: "Information about Astelta and our goals",
      imageUrl: "https://yoursite.com/about-image.jpg",
    },
    stories: {
      title: "Stories",
      description: "Read stories from the world of Astelta!",
      imageUrl: "https://yoursite.com/stories-image.jpg",
    },
    human:{
        title: "Human",
        description: "An overview of Human's abilities and his patch logs",
        imageUrl: "https://yoursite.com/stories-image.jpg",
    }
  };
  