import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async'; // Import HelmetProvider
import './styles/App.scss';

// Components
import Header from './modules/Header';
import { HomePage, AboutPage, StoriesPage, HumanPage, StoryPage } from './pages';

// Firebase
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCgXu6sflMmx830wVxyiQjnWw4qdj7C2bM",
  authDomain: "astelta-59887.firebaseapp.com",
  databaseURL: "https://astelta-59887-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "astelta-59887",
  storageBucket: "astelta-59887.firebasestorage.app",
  messagingSenderId: "918704959265",
  appId: "1:918704959265:web:87d3361eefb0275779d860",
  measurementId: "G-K99EGYL9TX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <HelmetProvider>
      <Router>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/stories" element={<StoriesPage />} />
            <Route path="/stories/:id" element={<StoryPage />} /> {/* Dynamic Route */}
            <Route path="/human" element={<HumanPage />} />
          </Routes>
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
