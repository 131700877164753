import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <header>
      <nav>
        <div className="Logo">
          <Link to="/">Astelta</Link>
        </div>
        <div className="NavLinks">
          <Link to="/">Home</Link> | 
          <Link to="/about">About</Link> | 
          <Link to="/stories">Stories</Link>
        </div>
        <div className="HumanLink">
          <Link to="/human">Human</Link>
        </div>
      </nav>
    </header>
  );
}

export default Header;
