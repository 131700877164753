import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get, child } from 'firebase/database';

function Stories() {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  // Function to apply random delays
  const applyRandomDelays = () => {
    const elements = document.querySelectorAll('.fadeIn');
    elements.forEach(element => {
      const randomDelay = Math.random() * 2 + 1; // 1-3 seconds
      element.style.animationDelay = `${randomDelay}s`;
    });
  };

  // Function to fetch articles from Firebase
  const fetchArticles = async () => {
    try {
      const dbRef = ref(getDatabase());
      const snapshot = await get(child(dbRef, 'articles'));
      if (snapshot.exists()) {
        const data = snapshot.val();
        const articlesArray = Object.keys(data).map(key => ({
          id: key,
          ...data[key],
        }));
        setArticles(articlesArray);
      } else {
        console.error("No data available");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Effect for fetching data
  useEffect(() => {
    fetchArticles();
  }, []);

  // Effect for applying animation delays after articles are loaded
  useEffect(() => {
    if (!loading && articles.length > 0) {
      // Small timeout to ensure DOM is ready
      setTimeout(applyRandomDelays, 100);
    }
  }, [loading, articles]);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className='gridStoriesDivContainer'>
      {articles.length > 0 ? (
        articles.map(article => (
          <div key={article.id} className="gridStoriesDiv fadeIn">
            {article.image && (
              <div className="imageContainer">
                <img src={article.image} alt={article.title} />
              </div>
            )}
            <h2>{article.title}</h2>
            <p>{truncateText(article.content, article.image ? 150 : 500)}</p>
            <a href={`/stories/${article.id}`} className="readMoreLink">
              Read more...
            </a>
          </div>
        ))
      ) : (
        <p>Nothing here.</p>
      )}
    </div>
  );
}

export default Stories;