import { Helmet } from 'react-helmet-async';

function DynamicHeadComponent({ title, description, imageUrl }) {
  return (
    <Helmet>
      <title>{`${title} | Astelta`}</title> {/* Upewnij się, że to jest string */}
      <meta name="description" content={description} />
      
      {/* Open Graph tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {imageUrl && <meta property="og:image" content={imageUrl} />}
    </Helmet>
  );
}

export default DynamicHeadComponent;
