import React from 'react';
import DynamicHeadComponent from './modules/Head';
import Home from './pages/Home';
import Stories from './pages/Stories';
import Human from './pages/Human';
import Story from './modules/Story'; // Import Story component
import { metadata } from './metadata';
import { useParams } from 'react-router-dom';

export const HomePage = () => (
  <>
    <DynamicHeadComponent {...metadata['home']} />
    <Home />
  </>
);

export const AboutPage = () => (
  <>
    <DynamicHeadComponent {...metadata['about']} />
    <h2>O Mnie</h2>
  </>
);

export const StoriesPage = () => (
  <>
    <DynamicHeadComponent {...metadata['stories']} />
    <Stories />
  </>
);

export const HumanPage = () => (
  <>
    <DynamicHeadComponent {...metadata['human']} />
    <Human />
  </>
);

// StoryPage to handle individual story routes
export const StoryPage = () => {
  const { id } = useParams(); // Extract the ID from the URL
  return (
    <>
      <DynamicHeadComponent {...metadata['story']} />
      <Story id={id} /> {/* Pass ID to Story component */}
    </>
  );
};
